import React, { useRef, useState } from "react";
import {
  useGetApplicationQuery,
  useGetSingleApplicationQuery,
  useUpdateApplicationMutation,
} from "../../store/slices/apis/applicationApi";
import { IMAGE_BASE_URL } from "../../Utils/Common";
import {
  useGetCollegesQuery,
  useGetCoursesQuery,
  useGetDegreeModesQuery,
  useGetDegreeTypesQuery,
  useGetDocumentsQuery,
  useGetGradesQuery,
  useGetSubjectsQuery,
} from "../../store/slices/apis/masterApi";
import ImageModel from "../../Components/Common/ImageModel";
import { toast } from "react-toastify";
import Loader from "../../Components/Utils/Loader";
import { PaymentStatus } from "../../Utils/Status";
import { useParams } from "react-router-dom";
import { RiCheckboxFill } from "react-icons/ri";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

export const ApplicationDetail = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { application_id } = useParams();
  console.log(application_id);
  const handleCheckBoxChange = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const allChecked = Array.from(checkboxes).every(
      (checkbox) => checkbox.checked
    );
    setIsChecked(allChecked);
  };

  const [isLoading, setLoading] = useState(false);

  const [showImageModel, setShowImageModel] = useState(null);
  const printRef = useRef();

  const Print = useReactToPrint({
    content: () => printRef.current,
  });

  const {
    data: application,
    isLoading: isLoadingGetApplication,
    isSuccess: isSuccessGetApplication,
    isError: isErrorGetApplication,
  } = useGetSingleApplicationQuery(application_id);

  const {
    data: documents = [],
    isLoading: isGetDocumentsLoading,
    isFetching: isGetDocumentsFetching,
    isSuccess: isGetDocumentsSuccess,
  } = useGetDocumentsQuery(application?.certificate_id || 1);

  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();
  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();
  const {
    data: degreeModes = [],
    isLoading: isLoadingGetDegreeModes,
    isSuccess: isSuccessGetDegreeModes,
    isError: isErrorGetDegreeModes,
  } = useGetDegreeModesQuery();
  const {
    data: subjects,
    isLoading: isLoadingGetSubjects,
    isSuccess: isSuccessGetSubjects,
    isError: isErrorGetSubjects,
  } = useGetSubjectsQuery();

  const {
    data: degreeTypes = [],
    isLoading: isLoadingGetDegreeTypes,
    isSuccess: isSuccessGetDegreeTypes,
    isError: isErrorGetDegreeTypes,
  } = useGetDegreeTypesQuery();

  return (
    <div ref={printRef}>
      {isLoading && <Loader />}
      <div className='text-center'>
        {/* {application?.payment_status === PaymentStatus.PAID && ( )} */}

        <img
          src='../../assets/DU.png'
          alt='university_logo'
          className='w-3/6 m-auto'
        />
        <h2 className='text-2xl font-bold'>
          Application form for XXII Convocation
        </h2>
      </div>

      {showImageModel && (
        <ImageModel
          url={showImageModel}
          onClose={() => setShowImageModel(null)}
        />
      )}
      <div className='w-full p-10 space-y-8 bg-white'>
        <div className=''>
          <div className='text-lg font-bold'>
            Application No.: {application?.id}
          </div>
          <div className='text-lg font-bold'>
            Applied Date:{" "}
            {moment(application?.transaction_date).format("DD/MM/YYYY")}
          </div>
          <div className='text-lg font-bold'>
            Status:{" "}
            <span className='text-green-600'>Successfully Submitted</span>
          </div>
          <hr className='m-4' />
          <div className='flex flex-wrap'>
            <div className='w-2/6 space-y-2 font-bold'>
              <div>Name of the Candidate (English):</div>
              <div>Name of the Candidate (Assamese):</div>
            </div>
            <div className='w-4/6'>
              <div className='relative flex'>
                <div className='w-2/3 space-y-2'>
                  <div>{application?.name}</div>
                  <div className='font-Banikanta'>
                    {application?.assamese_name}
                  </div>
                  {application?.assamese_name?.length == 0 && (
                    <div>
                      <RiCheckboxFill className='inline' /> I don't know how to
                      write my name in Assamese. Therefore, I authorize the
                      Certificate Branch, DU to convert my name to Assamese on
                      my behalf. I will have No Objection on the spelling of my
                      name in Assamese converted by the Certificate Branch, DU.
                    </div>
                  )}
                </div>
                <div className='absolute right-0 w-1/3 space-y-2'>
                  {application?.applicant_photo && (
                    <img
                      className='h-32 mt-auto ml-auto'
                      src={`${IMAGE_BASE_URL}${application?.applicant_photo}`}
                      alt='ProfilePhoto'
                    />
                  )}
                  {application?.signature && (
                    <img
                      className='w-32 h-10 mt-auto ml-auto'
                      src={`${IMAGE_BASE_URL}${application?.signature}`}
                      alt='Signature'
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Father's Name:</div>
            <div className='w-4/6'>{application?.father_name}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              Registration No of Dibrugarh University:
            </div>
            <div className='w-4/6'>{application?.registration_no}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Degree applied for :</div>
            {isSuccessGetCourses && (
              <div className='w-4/6'>{application?.course?.name ?? ""}</div>
            )}
          </div>

          {application?.degree_type_id > 0 && (
            <div className='flex flex-wrap space-y-2'>
              <div className='w-2/6 font-bold'>
                Honours/Major/Distinction/General :
              </div>

              <div className='w-4/6'>
                {degreeTypes?.find(
                  (type) => type.id === application?.degree_type_id
                )?.name || ""}
              </div>
            </div>
          )}
          {application?.subject_id > 0 &&
            [1, 3, 4].includes(application?.degree_type_id) && (
              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>Subject/Speciality :</div>
                {isSuccessGetSubjects && (
                  <div className='w-4/6'>
                    {subjects?.find(
                      (subject) => subject.id === application?.subject_id
                    )?.name || ""}
                  </div>
                )}
              </div>
            )}
          {application?.degree_mode_id > 0 && (
            <div className='flex flex-wrap space-y-2'>
              <div className='w-2/6 font-bold'> Mode :</div>
              {isSuccessGetDegreeModes && (
                <div className='w-4/6'>
                  {degreeModes?.find(
                    (mode) => mode.id === application?.degree_mode_id
                  )?.name || ""}
                </div>
              )}
            </div>
          )}
          {application?.phd_notification_no?.length > 0 &&
            application?.course_id === 40 && (
              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>
                  Notification No. and Date :
                </div>

                <div className='w-4/6'>{application.phd_notification_no}</div>
              </div>
            )}

          {/* <div className="flex flex-wrap space-y-2">
            <div className="w-2/6 font-bold">Year of qualifying :</div>
            <div className="w-4/6">{application?.year_of_qualify}</div>
          </div> */}

          {application?.course_id != 40 && (
            <>
              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>Roll & No :</div>
                <div className='w-4/6'>
                  {application?.exam_roll_no ? (
                    application?.exam_roll_no
                  ) : (
                    <>
                      {application?.exam_roll} - {application?.exam_no}
                    </>
                  )}
                </div>
              </div>

              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>Class/Division/Grade :</div>
                {isSuccessGetGrades && (
                  <div className='w-4/6'>
                    {grades?.find((grade) => grade.id === application?.grade_id)
                      ?.name || ""}
                  </div>
                )}
              </div>
            </>
          )}
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              Final Semester/Year examination held in (Month/Year) :
            </div>
            <div className='w-4/6'>
              {moment(application?.final_sem).format("MM/YYYY")}
            </div>
          </div>

          {/* <div className="flex flex-wrap space-y-2">
            <div className="w-2/6 font-bold">Year of Qualifying :</div>
            <div className="w-4/6">{application?.year_of_qualify}</div>
          </div> */}

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Date of passing :</div>
            <div className='w-4/6'>
              {moment(application?.date_of_passing).format("DD/MM/YYYY")}
            </div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              College/Department/Institute from where qualified :
            </div>
            {isSuccessGetColleges && (
              <div className='w-4/6'>
                {colleges?.find(
                  (college) => college.id === application?.college_id
                )?.name || ""}
              </div>
            )}
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Mobile No :</div>
            <div className='w-4/6'>{application?.phone_number}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Email :</div>
            <div className='w-4/6'>{application?.email}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Address for Communication:</div>
            <div className='w-4/6'>
              <p>
                Village/Town: {application?.village}, House No./Street No.:
                {application?.house_no}, P.O: {application?.postal_po},
                District:
                {application?.district}, State: {application?.state}, Pin:
                {application?.pincode}
              </p>
            </div>
          </div>

          {application?.documents &&
            application?.documents?.map((document) => {
              const exists = application?.documents?.find(
                (doc) => doc.document_id === document.document_id
              );
              return (
                <div key={document.id} className='flex flex-wrap space-y-2'>
                  <div className='w-2/6 font-bold'>
                    {documents.find((d) => d.id == document.document_id)?.name}
                  </div>
                  {exists?.file ? (
                    <span className='px-4 text-green-700 bg-green-100 rounded'>
                      Uploaded
                    </span>
                  ) : (
                    <span className='px-4 py-2 text-gray-700 bg-gray-100 rounded'>
                      Not Uploaded
                    </span>
                  )}
                </div>
              );
            })}
        </div>

        <hr className='my-6 border border-gray-400 border-opacity-50' />

        <div class='container mx-auto'>
          <div class='p-2'>
            <h2 class='font-bold text-xl'>UNDERTAKING</h2>
            <p class='text-lg'>I hereby declare the following,</p>

            <div class='text-lg space-y-1'>
              <div class='space-x-2'>
                <input
                  checked
                  id='checkbox1'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label class='font-semibold' for='checkbox1'>
                  I have uploaded the correct documents during the online
                  application and ensured that the scanned images of such
                  documents are distinctly visible.
                </label>
              </div>

              <div class='space-x-2'>
                <input
                  checked
                  id='checkbox2'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label class='font-semibold' for='checkbox2'>
                  I have provided my own mobile No. and email id at the time of
                  online application.
                </label>
              </div>

              <div class='space-x-2'>
                <input
                  checked
                  id='checkbox3'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label class='font-semibold' for='checkbox3'>
                  If any of the information provided by me is found to be
                  incorrect/illegible, my application shall be liable to be
                  cancelled.
                </label>
              </div>
            </div>

            <hr class='border border-gray-400 border-opacity-50 my-6' />

            <div>
              <h2 class='font-bold text-xl'>PAYMENT INFO</h2>
              <p class='text-lg font-semibold'>
                Transaction Date: {application?.transaction_date} <br />
                Applied For: {application?.payment_type} <br />
                Transaction No: {application?.transaction_no} <br />
                Bank Reference No: {application?.bank_ref_no} <br />
                Amount: {application?.amount_paid} <br />
              </p>
            </div>

            <hr />
            <div className='flex flex-col items-center justify-center'>
              <button
                className='flex items-center px-6 py-2 mt-1 text-lg font-normal leading-normal text-white no-underline whitespace-no-wrap bg-teal-500 border rounded select-none noPrint hover:bg-teal-600 screen-only'
                onClick={Print}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetail;
